@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Luxurious+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Luxurious+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Luxurious+Script&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Lobster&family=Luxurious+Script&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Karla&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&family=Readex+Pro&family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Karla&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&family=Readex+Pro:wght@300;400&family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Luxurious+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Luxurious+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Luxurious+Script&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Karla&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&family=Cedarville+Cursive&family=Courgette&family=Gwendolyn&family=Indie+Flower&family=Karla&family=Kaushan+Script&family=Lobster&family=Luxurious+Script&family=Nanum+Myeongjo:wght@800&family=Pacifico&family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*font family*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
:root {
  --blue-color: #110874;
  --second-color :#43121d;
  --third-color: #f98169;
  --fourth-color:#555;
}


/* #myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
} */
.carousel-heading {
    color:var(--third-color);
}
.carousel-p {
    color: rgb(216, 216, 216);
    text-transform: capitalize;
    font-size: 12px;
}
.carousel-inner {
    text-align: center;
    height: 50vh;
}
.carousel-item {
    text-shadow: 1px 2px 30px rgba(53, 53, 53, 0.3);
    z-index: 2000; 
    margin-top: 10rem;
}
.carousel-h3 {
    /* font-size: 2rem; */
    color: rgb(245, 245, 245);
    text-transform: capitalize;
    z-index: 10000;
    font-family: 'Lobster', cursive;

}
.carousel-item {
    margin-top: 10rem
}
.carousel-control-prev,.carousel-control-next {
    margin-top: 10rem;
}

.carousel-btn {
    margin-top: 10rem;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Open Sans',Helvetica,Arial, sans-serif;
    
}
/* 
@font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('Segoe UI.woff') format('woff');
    }
@font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('Segoe UI Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('Segoe UI Bold.woff') format('woff');
    }
     */

 
body::-webkit-scrollbar {
    display:"none";
}

.bold-text {
    font-size: 54px;
    font-weight: 500;
}
.banner-text {
    font-size: 18px;
}
.paragraph {
    font-size: 15px;
    color: rgb(66, 66, 66);
    text-align: justify;
}
.paragraph-wwe {
    font-size: 16px;
    /* color: rgb(66, 66, 66); */
    color: #010101;
    text-align: center;
}
.paragraph-wd {
    font-size: 15px;
    /* color: rgb(66, 66, 66); */
    color: #010101;
    text-align: center;
}
.paragraph-wdj {
    font-size: 15px;
    /* color: rgb(66, 66, 66); */
    color: #010101;
    text-align: justify;
}
.paragraph-srv {
    font-size: 15.5px;
    /* color: rgb(66, 66, 66); */
    color: #272727;
    text-align: center;
}
.paragraph-thickblue {
    font-size: 15px;
    color: rgb(41, 29, 70);
    text-align: justify;
} 
.three-head-h1 {
    color: #301c58;
    font-weight: 700;
}
.text {
    position: absolute;
    top: 10%;
    color: gainsboro;
    /* z-index: ; */
    /* background-color: rgba(0, 0, 0,.); */
    width: 100%;
    height: 100vh;
}
 video {
     position: relative;
     width: 100%;
     height: 100vh;
     object-fit: cover;
     transition: all 1.2s linear;
 }
 /* .video1 {
     opacity: 1;
     z-index: -3;
 } */

 .video2 {
    opacity: 0;
}
.video3 {
    opacity: 0;
}

/* .container-fluid1 {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0, 0, 0.4);
    text-align: center;
} */

.navbar {
    z-index: 20000;
    position: fixed;
    top: 0px;
    z-index: 5000000;
    /* left: 6.5rem; */
    /* background: #1d1d1d; */
    transition: .4s all;
}
.navbar-h1 {
    color: seashell;
}
.nav-link {
    color: seashell;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 500;
    /* margin-left: 2rem; */
    letter-spacing: 1px;
    border: none;
    transition: .3s all;
    /* margin-right: 100px; */
    color: rgb(231, 228, 225);
    cursor: pointer;
}
.nav-link:hover {
    color: skyblue;
}
.navbar-collapse {
    width: 30%;
} 
.text-none {
    text-decoration: none;
}
.seltam-brand {
    font-size: 21px;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgb(255, 10, 182);
}
/* .nav-link:hover {
    border-bottom: 1px solid seashell;
    color: seashell;
} */

.overlay {
    height: 100vh;
    width: 100%;
    /* background-color: rgba(71, 71, 71, 0.4); */
    background: linear-gradient(to left bottom,rgba(27, 24, 24, 0.8), rgba(165, 165, 167, 0.1));
    z-index: 1;
    position: absolute;
    top: 0px;
}
.nav-all-content {
    position: absolute;
    top: 0px;
}
.nav-btn {
    margin-left: 4rem;
    width: 150px;
    background-color: transparent;
    outline: seashell 1px solid;
    color: seashell;
    padding: 10px 0px;
    font-family: 'Readex Pro', sans-serif;
    border: none;
    transition: .3s all;
}
.nav-btn:hover {
    width: 150px;
    background-color: seashell;
    outline: seashell 1px solid;
    color: rgb(248, 94, 23);
    padding: 10px 0px;
    border: none;
}
/* .nav-all-content {
 
} */


span {
    font-size: 41px;
}

.below-container {
    color: #255bf7;
    font-weight: 800;
    font-size: 58px;
}
.below-container-wd {
    color: #255bf7;
    font-weight: 600;
    font-size: 45px;
}
.below-container-2 {
    margin-top: -10px;
    font-weight: 800;
    color: #401793;
    font-size: 40px;
}

.third {
    position: relative;
    top: 0%;
    overflow: hidden;
}

.water-mark-1 {
    font-weight: bold;
    font-size: 3rem;
     position: absolute; 
    top: 53px; 
    left: 50px;
    z-index: 0;
    opacity: .2;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
    color: grey;
}
.water-mark-2 {
    position: absolute; 
    top: 512px; 
    right: 160px;
    opacity: .2;
    font-weight: bold;
    font-size: 3rem;
    z-index: 0;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
    color: grey;
}
.water-mark-3 {   
    position: absolute; 
    top: 969px; 
    left: 50px;
    opacity: .2;
    font-weight: bold;
    z-index: -20;
    font-size: 3rem;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
    color: grey;
}

li {
    list-style-type: none;
    font-size: 15px;
    color: rgb(105, 105, 105);
    text-align:center;
}



#our_process {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff;
}

.fourth-h2 {
    color: rgb(56, 56, 56);
  padding-top: 30px;
}
.fourth-p {
    font-size: 15px;
    color: rgb(34, 34, 34);
    text-align: center;
}

.fifth-h1-1 {
    margin-top: -10px;
    font-weight: 800;
    color: #e1e0e2;
    font-size: 30px;
}
.fifth-h1-2 {
    margin-top: -10px;
    font-weight: 600;
    color: #401793;
    font-size: 40px;
}
.fifth-h1-3 {
    margin-top: -10px;
    font-weight: 500;
    color: #064b72;
    font-size: 25px;
}
.fifth-h1-4 {
    margin-top: 1px;
    font-weight: 300;
    color: #080808;
    font-size: 20px;
}

.fa-laptop,.fa-laptop-medical,.fa-chart-line {
    /* outline: 2px solid #301c58; */
    padding: 20px;
    margin: 10px;
    color: #301c58;
    transition: all .2s ease-in-out;
    border-radius: 70px;
}

.fa-laptop:hover, .fa-laptop-medical:hover, .fa-chart-line:hover {
    color: #301c58;
    /* outline: 2px solid rgb(231, 85, 0); */
    -webkit-transform: scale(.9);
            transform: scale(.9);
}

/* #three-service {
    box-shadow: 3px 2px 65px rgb(131, 131, 131,.2);
    background-color: rgb(245, 245, 245);
    padding: 10px;
    border-radius: 5px;
} */
#three-service-tech {
     box-shadow: 3px 20px 25px rgb(131, 131, 131,.1)
               ,inset  19px 2px 165px rgb(131, 131, 131,.1);
    ; 
    background-color: rgb(212, 212, 212);
    border: 1px solid rgb(102, 101, 101,.1);
    padding: 10px;
    border-radius: 5px;
    transition: .3s all ease-in-out;

}
/* #three-service-tech:hover {
    transform: scale(1.1); */
    /* box-shadow: 3px 2px  65px rgb(131, 131, 131,.2)
               ,inset 19px 2px 265px rgb(131, 131, 131,.4);
    ; */
    /* background-color: rgb(251, 251, 251); */

/* } */

.fifth-h3 {
    font-weight: 500;
    font-size: 20px;
    color: #301c58;
   
}
.sixth-h3 {
    color: seashell;
}
.full-footer {
    background: linear-gradient(rgb(25, 26, 36),#1b1820);
}
#footer {
    height: 60vh;
}
.ft {
    overflow: hidden;
}
#footer-2 {
    background: linear-gradient(rgb(25, 26, 36),#1b1820);}
address {
    color: seashell;
    font-family:'Times New Roman', Times, serif;
}
.footer-p {
    font-family:'Times New Roman', Times, serif;
    color: seashell;
    font-weight: 800;
    font-size: 18px;
}
.fa-facebook-square, .fa-twitter-square,.fa-instagram-square {
    color: seashell;
    font-size: 28px;
    margin-left: 2px;
}
.footer-btn {
    width: 210px;
    outline: seashell 1px solid;
    border: none;
    background-color: transparent;
    color: seashell;
    padding: 3px;
    transition: .3s all ease-in-out;
}
.footer-btn:hover {
    background-color: orangered;
    outline: none;
}

.Lm-btn {
    width: 140px;
    padding: 4px;
    /* outline: 1px solid orangered; */
    border: none;
    color: orangered;
    font-size: 12px;
    margin-top: 24px;
    transition: .2s all;
}
.Lm-btn:hover {
    width: 140px;
    /* padding: 8px; */
    outline: none;
    background-color: #255bf7;
    border: none;
    color: rgb(236, 236, 236);
    margin-top: 24px;
    transition: .2s all;
}
.sp-btn {
    width: 140px;
    padding: 8px;
    outline: none;
    background-color: rgb(151, 149, 149,.4);
    border: none;
    color: rgb(5, 5, 5);
    margin-top: 24px;
    transition: .3s all;
}
.sp-btn:hover {
    width: 140px;
    padding: 8px;
    outline: none;
    background-color: #255bf7;
    border: none;
    color: rgb(179, 179, 179);
    margin-top: 24px;
    transition: .2s all;
}
:root {
    color:  "#255bf7";
}
.f-li {
    color: white;
    font-size: 11px;
    margin-left: -20px;
}
.process-h3 {
    color: seashell;
    font-size: 15px;
    font-weight: 500;
}
.proccess-btn {
    background-color: transparent;
    outline: springgreen 1px solid;
    padding:  12px 16px;
    line-height: 1rem;
    border: none;
    color: springgreen;
    border-radius: 120px;
}



.display-6,.display-5 {
    font-weight: bold;
}
p {
    font-size: 13px;
}

.our_heading {
    font-weight: 600;
    font-size: 21px;
}
.link {
    color: #ffffff;
}
.link:hover {
    color: black;
    transition: .4s all;
    background-color: rgb(235, 233, 233);
    -webkit-transform: scale(.9);
            transform: scale(.9);
    font-weight: 500;
}
.dropdown-menu {
    background-color: rgb(22, 22, 22,.4);
    display: flex;
    flex-direction: column;
    justify-content: start;
}



#dropdown_1 {
    display: none;
}
#dropdown_2 {
    display: none;
}
.heading {
    margin-top: 50px;
    /* z-index: 2000; */
    font-family: 'Open Sans',Helvetica,Arial, sans-serif;
    line-height: 1.2;
    font-weight: 600;
    font-size: 3.4rem;
    color: #E9E9E9;
}


/* @media only screen and (max-width: 500px) {
    .home_image {
        width: 410px;
        margin-left: -112.5px;
    }
} */
/* @media only screen and (max-width: 600px) {
    .home_image {
        width: 460px;
        margin-left: -134px;
    }
} */

.carousel-control-next,.carousel-control-prev {
    z-index: 2000000;
}

#our_service {
    background: linear-gradient(to left bottom,rgba(40, 40, 70), rgb(43, 43, 53,.3));
}

#footer_map::-webkit-scrollbar {
    display: none;
}

.img-fluiid {
    border-radius: 3px;
}

.navigation-btn {
    display: none;
    position: fixed;
    box-shadow: 2px 2px 300px rgb(83, 83, 83,4);
    top: 560px;
    right: 10px;
    /* outline: rgb(0, 0, 0) 3px solid; */
    border: none;
    border-radius: 80px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: whitesmoke;
    color: rgb(20, 20, 20);
    font-weight: 400;
    font-size: 18px;
    z-index: 2000000;
    padding: 7px 14px;
    -webkit-animation: rotate infinite 2s ;
            animation: rotate infinite 2s ;
    transition: .6s all;
}
.navigation-btn:hover {
    background-color: rgb(255, 255, 255);
    -webkit-transform: scale(.9);
            transform: scale(.9);
}
.navigation-btn:focus {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
    position: fixed;
    width: 600px;
    top: 560px;
    right: 10px;
    outline: rgb(255, 145, 1) 3px solid;
    border: none;
    border-radius: 60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: whitesmoke;
    color: rgb(248, 127, 15);
    font-weight: 400;
    font-size: 18px;
    z-index: 2000000;
    padding: 10px 20px;
    -webkit-animation: rotate infinite 2s ;
            animation: rotate infinite 2s ;
    transition: .6s all;
}
.learn-more {
    color: orangered;
}
.learn-more:hover {
    color: rgb(228, 228, 228);
}


.copy-text {
    color: rgb(167, 167, 167);
}
.seltam-text {
    color: rgb(240, 118, 61);
}
@-webkit-keyframes rotate {
    from{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    to{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    10% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    75% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}
@keyframes rotate {
    from{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    to{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    10% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    75% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

.border-bg {
    padding: 10px;
    border-radius: 7px;
    border: 1px solid rgb(187, 187, 187,.4);
    background-color: rgb(255, 255, 255);
}
.our-service {
    background-color: rgb(246, 246, 246);
}
.paragraph-wwe {
    color: black;
    font-size: 18px;
}

/* footer new css */

#footer {
    background-color: #131313;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* height: 40vh; */
}
.h4 {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 2.1rem;
    letter-spacing: 1px;
}
.h3 {
    color: #fff;
    font-weight: 400;
    font-size: 17px;
    line-height: 2.1rem;
    letter-spacing: 0.6px;
}
.footer-orange-btn {
    text-transform: uppercase;
    background: #f0632c;
    z-index: 99;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    outline: none;
    border: none;
    padding: 10px 20px;
    letter-spacing: .03rem;
    border-radius: 4px;
}
.footer-para {
    color: #6a6a6a;
    font-weight: 400;
    font-size: 16px;
    /* letter-spacing: 1px; */
    line-height: 1.7rem;
}
.footer-p-link {
    color: #6a6a6a;
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
    /* letter-spacing: 1px; */
    line-height: 1rem;
}

.footer-twitter-btn {
    padding: 4px 20px;
    border: 2px solid #1da1f2;
    border-radius: 4px;
    color: #f1f1f1;
    outline: none;
    width: 130px;
    background: #1da1f2;
}

.footer-fb-btn {
    padding: 4px 20px;
    border: 2px solid #1da1f2;
    border-radius: 4px;
    color: #1da1f2;
    outline: none;
    width: 130px;
    background: transparent;
}

.bg-img-holder {
    background: linear-gradient(160deg,rgba(0, 0, 0, 0.9), rgb(44, 41, 41,.4)),url(/static/media/websiteredesigning.95064550.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.h2 {
    color: #fafafa;
    font-weight: 300;
    line-height: 1.3;
    font-size: 2.3647rem;
}

.bold-txt {
    font-weight: 500;
    font-size: 30px;
    word-spacing: 2px;
    letter-spacing: .5px;
}

.wwe-p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
}
.wwe-p-white {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #efef;
}
.wwe-p-c {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
    text-align: center;
}
.wwe-h3 {
    line-height: 1.2;
    font-size: 1.77689rem;
    font-weight: 500;
    /* color: #0a2d63; */
    color: #394387;
}

.border-box {
    border: 1px solid rgb(153, 151, 151,.4);
    border-radius: 5px;
    padding: 50px 30px;
}
.border-down {
    border-bottom: rgb(153, 151, 151,.4) 1px solid;
    width: 10%;
    
}
.wwe-p-service  {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
    text-align: justify;
}
@media only screen and (max-width: 900px) {
    .water{
      display: none;
    }
  }
.water {
   /* min-height: 100px; */
    min-width: 400px;
    -webkit-transform: rotate(-90deg);
    letter-spacing: 1px;
    /* transform: translateY(36px); */
    color: #6a6a6a;
    opacity: .5;
    font-weight: 700;
}

/* @media only screen and (max-width: 600px) {
   
  } */

.homewebapp-img {
    border-radius: 8px;
}
.recruit-btn {
    padding: 10px 10px;
    border-radius: 19px;
    letter-spacing: 1.1px;
    width: 160px;
    border: none;
    outline: none;
    background-color: #394387;
    color: #efefef;
    font-size: 14px;
    box-shadow: 1px 1.1px 2px  rgba(136, 136, 136, 0.3);
    transition: all .5s;
}


.recruit-btn:hover {
    padding: 10px 10px;
    border-radius: 19px;
    letter-spacing: 1.1px;
    width: 160px;
    border: 2px solid #394387;
    outline: none;
    background-color:transparent;
    color: #394387;
    font-size: 14px;
    box-shadow: 1px 1.1px 2px  rgba(136, 136, 136, 0.3);
    transition: all .5s;
}

.blend-img {
    border-radius: 8px;
}
.border-orange {
    border-bottom: 1.3px solid rgb(231, 97, 48);
    width: 30%;
}


.img-software-1 {
    background: url(/static/media/e_com_2.e4a65b91.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
    border-radius: 30px 0px 30px 0px;
}
.img-software-2 {
    background: url(/static/media/CRM_1.0b6a567c.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
    border-radius: 30px 0px 30px 0px;
}
.img-software-3 {
    background: url(/static/media/emd_new.7ab9706d.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
    border-radius: 30px 0px 30px 0px;
}
.img-software-4 {
    background: url(/static/media/maintenance.2b5cde44.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
    border-radius: 30px 0px 30px 0px;
}
.software-border {
    border-bottom: 1px solid rgb(112, 111, 111,.4);
    width: 50%;
}



/*font family*/

* {
  margin: 0px;
  padding: 0px;
  /* box-sizing: border-box; */
  font-family: "Poppins", sans-serif;
}
:root {
  --blue-color: #110874;
  --second-color: #43121d;
  --third-color: #f98169;
  --fourth-color: rgb(114, 113, 113);
}

/* .about_heading_h3,.about_heading_h3_span {
      color: var(--blue-color);
      font-family: 'Nanum Myeongjo', serif;
    } */
/* .about_heading_h3 {
        color: var(--third-color);
    } */
.about_heading_h3 {
  color: #f98169;
  color: var(--third-color);
  font-size: 2rem;
}

/* .about_img_1 {

    } */
/* .about_img_2 {
        transform: scale(.9);
        transition: .3s all ease-in-out;
    } */
/* .about_img_1:hover {
        transform: scale(.8);
    } */

.about_container {
  /* margin-top: -80px; */

  background: linear-gradient(160deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6)),
    url(/static/media/about.9ba3d6e7.jpg) no-repeat;
  /* background-position: center; */
  background-size: cover;
  /* box-shadow: 3px 4px 10px rgb(138, 138, 138,.3); */
  height: 80vh;
}
.smart-heading {
  color: white;
}
.pro {
  color: #110874;
  font-size: 30px;
}
.smart-child-heading {
  color: rgb(253, 253, 31);
  font-family: "Balsamiq Sans", cursive;
  font-size: 3rem;
}

.heading {
  font-size: 4rem;
  color: #f98169;
  color: var(--third-color);
}
.vision {
  color: #110874;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}
.hyper_link {
  transition: all 0.3s;
}

.fifth-h3 {
  font-weight: 500;
  font-size: 20px;
  color: #301c58;
}
.fifth-mv {
  /* color: seashell; */
  color: #9b9b9b;
  font-weight: 800;
  font-size: 40px;
}

.about_p {
  /* font-family: 'Karla', sans-serif; */
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  text-transform: capitalize;
  text-align: justify;
}
.about_p_white {
  /* font-family: 'Karla', sans-serif; */
  font-size: 15px;
  font-weight: 400;
  color: #f0ecec;
  text-transform: capitalize;
  text-align: justify;
}
.two-about {
  background-color: #2f2a53;
}

/* background-color: #2f2a53 */
.border-heading {
  border-bottom: 1px solid #9b9b9b;
}
.wwe-p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
}
.wwe-p-white {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #efef;
}
.wwe-p-c {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
    text-align: center;
}
.wwe-h3 {
    line-height: 1.2;
    font-size: 1.77689rem;
    font-weight: 500;
    /* color: #0a2d63; */
    color: #394387;
}

.border-box {
    border: 1px solid rgb(153, 151, 151,.4);
    border-radius: 5px;
    padding: 50px 30px;
}
.border-down {
    border-bottom: rgba(34, 34, 34, 0.4) 1px solid;
    width: 10%;
    
}
.wwe-p-service  {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .4px;
    line-height: 1.6;
    color: #7f7f7f;
    text-align: justify;
}
/* .images-of-about {
    background: url(./assest/seltam_img/Vision.jpg);
} */
.vision-img {
    border-radius: 6px;
}
.border-orange {
    border-bottom: 1.3px solid rgb(231, 97, 48);
    width: 30%;
}
.about-btn {
    color: #efefef;
    background-color: #301c58;
    font-weight: 300;
    padding: 7px 24px;
    border: none;
    border-radius: 6px;
    outline: none;
}
.end-flex {
    background: linear-gradient(100deg,rgb(24, 23, 23,.9), rgb(8, 8, 8,.8)),url(/static/media/pexels-ivy-son-3490393.018df03b.jpg);
    background-position: center;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 50px 80px;
}
.border-flex {
    border: 2px solid rgb(97, 97, 97);
    text-align: center;
    border-radius: 3px;
}
/* .parent-list {
    display: flex;
    text-align: left;
}
.dropdown-parent {
    font-size: 18px;
    color: #000;
    padding: 10px 20px;
    background: #484848;
}
.dropdown-item {
    font-weight: 700;
    font-size: 16px;
    color: black;
} */

.dropdown-box {
    background-color: rgb(34, 33, 33,.4);
    position: absolute;
    top: 10px;
}

  .dropdown_bg {
    background: #180f27;
    text-align: left;
  }
  .dr-a  {
      text-decoration: none;
      color: rgb(214, 214, 214);
      font-size: 14px;
      text-align: left;
      cursor: pointer;
      /* padding: 100px; */
  } 
  .x {
      display: flex;
      justify-content: flex-start;
      flex-direction:column;
      text-align: left;
  }
 
  .dropdown-link {
      text-align: left;
  }
  .dr-link {
      font-size: 18px;
      cursor: pointer;
  }
  .dr-hover:hover {
      color: #00bfff;
      transition: .3s all;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
  }
:root {
    --blue-color: #110874;
    --second-color :#43121d;
    --third-color: #f98169;
    --fourth-color:#555;
    --para-tag: #555;
  }


.service_heading {
    color:#f98169;
    color:var(--third-color);
}
.service_heading-span {
    color: #110874;
    color: var(--blue-color);
    font-family: 'Nanum Myeongjo', serif;
}
.service_img {
    background: linear-gradient(rgba(66, 100, 212, 0.5), rgb(3, 3, 126,.5)),url(/static/media/services.ab11b470.jpg);
    background-size: cover;
    height: 80vh;
}

.service-child-heading {
    font-family: 'Balsamiq Sans', cursive;
    color: rgb(255, 216, 42);
    font-size: 3rem;
}
.wd-h6 {
    /* margin-top: -10px; */
    font-weight: 500;
    color: #403a4d;
    font-size: 40px;
}
.wd-h4 {
    /* margin-top: -10px; */
    font-weight: 800;
    color: #401793;
    font-size: 50px;
}
.wd-h-small {
    font-weight: 600;
    color: #401793;
    font-size: 35px;
}
.wd-h-t-small {
    font-weight: 500;
    color: #401793;
    font-size: 21px;
}
.paragraph {
    font-size: 15px;
    color: rgb(66, 66, 66);
    text-align: justify;
}
.p-highlight {
    font-size: 15px;
    color: #401793;
    font-weight: 500;
}
.img-fluid-2 {
    border-radius: 200px 100px 100px 10px;
    transition: all .3s;
    outline: rgb(15, 15, 61,.3) 2px solid;
    z-index: -15;
}
.img-fluid-1 {
    border-radius: 500px 50px 50px 50px;
    transition: all .3s;
    outline:  rgb(15, 15, 61,.3)  2px solid;
    z-index: -15;
}
.img-fluid-2:hover {
    /* border-radius: 200px 100px 500px 10px; */
    box-shadow: 2px 3px 4px 10px rgb(22, 22, 22,.2);
    outline: none;
}
.img-fluid-1:hover {
    /* border-radius: 200px 100px 500px 10px; */
    box-shadow: 2px 3px 4px 10px rgb(15, 15, 15,.2);
    outline: none;
}
.wd_btn {
    padding: 10px;
    width: 140px;
    color: #110874;
    background-color: transparent;
    outline: 2px solid #110874;
    border: none;
    border-radius: 5px 2px 5px 2px;
    font-weight: 600;
    transition: all .3s;
}
.wd_btn:hover {
    padding: 10px;
    width: 140px;
    color: #efeff1;
    background-color: #110874;
    outline: none;
    border: none;
    border-radius: 5px 2px 5px 2px;
    font-weight: 600;
}
.dig_img {
    background: linear-gradient(to left bottom,rgba(7, 7, 7, 0.7), rgba(13, 13, 14, 0.5)),url(/static/media/homedigitalmarketing.6ce34cc0.jpg);
    background-size: cover;
    height: 80vh;
}
.bg-blue-digital-marketing {
    background-color: rgb(41, 71, 128);
    height: 50vh;
}
.bg-blue-digital-marketing-1 {
    background: url(/static/media/magnifying-glass-with-seo-concepts_1134-81.87d16ffa.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-2 {
    background: url(/static/media/marketing-social-media-background-with-funny-elements_1361-1264.cf1aeeaa.jpg);
    background-position:top;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-3 {
    background: url(/static/media/ContentMarketing.45eeffbd.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-4 {
    background: url(/static/media/1000_F_122058573_CGQ3wB9S2HljFJW1lLzxwziQEvZ5q8At.ce72131e.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-5 {
    background: url(/static/media/MobileAdvertising.e8a8f0a5.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.bg-blue-digital-marketing-6 {
    background: url(/static/media/othermarketing.829dbd5a.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
.below-h2-1-white {
    color: #efefef;
}
.digi-border {
    border-bottom: 1px solid white;
}
.software_img {
    background: linear-gradient(to left bottom,rgba(66, 100, 212, 0.1), rgba(13, 13, 14, 0.7)),url(/static/media/Softwaredevelopment.45b04b21.jpg);
    background-size: cover;
    height: 80vh;
}
.para-text {
    font-size: 15px;
    word-spacing: 2px;
}
#border-bottom {
    border-bottom: 2px solid rgb(2, 8, 66);
}
.img-fluid2 {
    height: 400px;
}
.web_img {
    background: linear-gradient(to left bottom,rgba(66, 100, 212, 0.1), rgba(13, 13, 14, 0.7)),url(/static/media/webdesign.91f25ec2.jpg);
    background-size: cover;
    background-position: center;
    height: 80vh;
}

.border {
    border-bottom: grey 2px solid;
}
#border-bottom {
    border-bottom: 2px solid rgb(2, 8, 66);
}
.img-fluid2 {
    height: 400px;
}
.para-text {
    font-size: 15px;
    word-spacing: 2px;
}

:root {
    --blue-color: #110874;
    --second-color :#43121d;
    --third-color: #f98169;
    --fourth-color:#555;
    --para-tag: #555;
  }

  .payroll_img {
    background: linear-gradient(to left bottom,rgba(46, 46, 47, 0.5), rgba(13, 13, 14, 0.4)),url(/static/media/Payrolloutsourcing.ae7a1ed6.jpg);
    background-size: cover;
    height: 80vh;
}
.rs-h4 {
    /* margin-top: -10px; */
    font-weight: 600;
    color: #401793;
    font-size: 40px;
}
/* .below-container-wd {
    color: #255bf7;
    font-weight: 600;
    font-size: 45px;
} */
.rs-h-small {
    font-weight: 500;
    color: #401793;
    font-size: 30px;
}
.rs-h-t-small {
    font-weight: 600;
    color: #401793;
    font-size: 21px;
}

.paragraph {
    font-size: 15px;
    color: rgb(66, 66, 66);
    text-align: center;
}
.paragraph-j {
    font-size: 15px;
    color: rgb(66, 66, 66);
    text-align: justify;
}
.paragraph-white {
    font-size: 15px;
    color: rgb(189, 189, 189);
    text-align: justify;
}
.p-highlight {
    font-size: 15px;
    color: #403a4d;
    font-weight: 500;
}
.rs-h6 {
    /* margin-top: -10px; */
    font-weight: 500;
    color: #401793;
    font-size: 25px;
}

.flex {
    padding: 50px 20px;
    background-color: #110874;
}

/* for payroll grid */

.payroll-grid {
    padding: 28px;
    min-height: 220px;
    width: 400px;
    border-radius: 8px;
    background-color: rgb(228, 228, 228);
}
.border-recruit {
    border-bottom: 1px solid #7f7f7f;
    opacity: .5;
    width: 30%;
}

  .recruit_img {
    background: linear-gradient(to left bottom,rgba(10, 10, 10, 0.5), rgba(13, 13, 14, 0.7)),url(/static/media/Recruitment.69beb9d9.jpg);
    background-size: cover;
    height: 80vh;
}
.rs-h-t-small {
  font-weight: 600;
  color: #401793;
  font-size: 21px;
}
.rs-h-small {
  font-weight: 600;
  color: #401793;
  font-size: 30px;
}

.recruit-grid {
  /* padding: 8px; */
  /* width: 400px; */
  border-radius: 7px;
  /* background-color: rgb(228, 228, 228); */
  background: rgb(88, 88, 88,.1);
  outline: 0.6px solid rgba(139, 138, 138, 0.3);
}
.mob_img {
    
        background: linear-gradient(to left bottom,rgba(43, 44, 46, 0.5), rgba(13, 13, 14, 0.7)),url(/static/media/appdevelopment.bae7f0e0.jpg);
        background-size: cover;
        background-position: center;
        height: 80vh;
    
}
.para-text {
        font-size: 15px;
        word-spacing: 2px;
    }

.heading {
        margin-top: 50px;
        /* z-index: 2000; */
        font-family: 'Open Sans',Helvetica,Arial, sans-serif;
        line-height: 1.2;
        font-weight: 600;
        font-size: 3.4rem;
        color: #E9E9E9;
}

.image-banner {
        background: linear-gradient(160deg,rgba(15, 15, 15, 0.6),rgb(20, 20, 20,.3)),url(/static/media/mobilemainimage.31647e17.jpg);
        height: 80vh;
        background-size: cover;
        background-position: center;
}
.paragraph-j1 {
        text-align: center;
        font-weight: 500;
        font-size: 17px;
        letter-spacing: -1px;
        /* word-spacing: 1px; */
        z-index: 3000;

}
.native-text {
        font-size: 17px;
        font-weight: 200;
        color: rgb(207, 207, 207);
}
.bg-proccess {
    /* background: url(./assest/seltam_img/pexels-ivy-son-3490393.jpg); */
    background-color: rgb(197, 197, 197,.5);
}
.below-container-1 {
    color: #255bf7;
    font-weight: 800;
    font-size: 58px;
}
.contact_img {
    background: linear-gradient(  to right bottom,rgba(5, 5, 7, 0.9), rgba(158, 63, 182, 0.8)),url("/static/media/contact_new (1).5ba1bc17.jpg");
    background-size: cover;
    margin-bottom: -20px;
    /* height: fit-content; */
    padding: 50px;
}
.display-2 {
    z-index: 12;
}
.contact {
    z-index: 30000;
}
.c-p {
    font-size: 12px;
    color: rgb(202, 202, 202);
}

.fa-handshake {
    position: absolute;
    top: 70%;
    right:0px;
    color: rgb(255, 255, 255);
    border-radius: 0px;
    padding: 15px;
    font-size: 37px;
    /* outline: 1px solid rgb(165, 164, 164,.1); */
    border-radius: 10px 5px;
    /* background: linear-gradient(  to right bottom,rgba(240, 240, 240, 0.9), rgba(158, 63, 182, 0.8)); */
    background-color: transparent;
}
.fa-handshake:hover {
    transition: .3s all ease;
    -webkit-transform: scale(.9);
            transform: scale(.9);
    color: rgb(27, 189, 189);
}

.pop_up_model {
    position: absolute;
    top: 20px;
    left: 3rem;
}

.c-input {
    outline: none;
    border: none;
    background: transparent;
    font-size: 21px;
    border-bottom: 1px rgb(167, 167, 167,.5) solid;
    width: 260px;
    font-size: 19px;
    padding: 10px;
}
.c2-input {
    outline: none;
    border: none;
    background: transparent;
    font-size: 21px;
    border-bottom: 1px rgb(167, 167, 167,.5) solid;
    width: 540px;
    font-size: 19px;
    padding: 10px;
}
.c-button {
    color: azure;
    font-weight: 500;
    font-size: 19px;
    background-color: rgb(32, 32, 48);
    outline: none;
    border: none;
    padding: 8px 10px;
}
/* .c-i  {
    margin-top: 12px;
} */
.ico {
    font-size: 11px;
}

.c2-form {
    width: 300px;
}

#body::-webkit-scrollbar {
    display: none;
  }
  
.footer_bg {
    background-color: rgb(26, 29, 48);
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    padding: 10px;
}
.f-link {
    color: honeydew;
    font-weight: 400;
    /* margin-left: 15px; */
    font-size: 15px;
}

.career_img {
    background: linear-gradient(to left bottom,rgba(48, 49, 53, 0.9), rgba(13, 13, 14, 0.7)),url(/static/media/career.d36fb8e6.jpg);
    background-size: cover;
    background-position: center;
    height: 90vh;
}
.career_img-apply-now {
    background: linear-gradient(to left bottom,rgba(48, 49, 53, 0.9), rgba(13, 13, 14, 0.7)),url(/static/media/career.d36fb8e6.jpg);
    background-size: cover;
    background-position: center;
    height: 40vh;
}
.fifth-h1-1 {
    /* color: seashell; */
    color: #e4e5e7;
    font-weight: 800;
    font-size: 40px;
    
}
.form-bg {
  background-color: rgb(255, 255, 255);
}
.btn-for-career {
    outline: none;
    border: none;
    background: rgb(13, 118, 189);
    width: 160px;
    padding: 12px 6px;
    color: rgb(213, 217, 219);
}
.career-full {
    background-color: rgb(110, 110, 110,.2);
    height: 1000px;
}
input[type=text], select {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 1px;
  box-sizing: border-box;
}
input[type=email], select {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 1px;
  box-sizing: border-box;
}


input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 10px 10px;
  margin: 8px 0;
  border: none;
  border-radius: 1px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.form-container {
  border-radius: 5px;
  background-color: #f4f3f3;
  padding: 20px;
}

.submit {
 width: 100%;
 background-color: #0d4185;
 color: white;
 padding: 7px 10px;
 align-items: center;
 margin: 8px 0;
 border: none;
 border-radius: 4px;
 cursor: pointer;
}
