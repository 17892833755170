input[type=text], select {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 1px;
  box-sizing: border-box;
}
input[type=email], select {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 1px;
  box-sizing: border-box;
}


input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 10px 10px;
  margin: 8px 0;
  border: none;
  border-radius: 1px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.form-container {
  border-radius: 5px;
  background-color: #f4f3f3;
  padding: 20px;
}

.submit {
 width: 100%;
 background-color: #0d4185;
 color: white;
 padding: 7px 10px;
 align-items: center;
 margin: 8px 0;
 border: none;
 border-radius: 4px;
 cursor: pointer;
}